import PropTypes from 'prop-types'
import { useState } from 'react'
import { purifyHtmlString } from 'services/utils'
import InfoMessage from '~/components/shared/InfoMessage'
import Heading from 'shared/Heading'
import ScrollBar from 'shared/ScrollBar'
import styles from './MembershipCardMessages.module.scss'
import Text from 'shared/Text'
import {
  HOME_PAGE_MESSAGE_ID,
  ALLOW_TARGET_BLANK,
} from './MembershipCardMessagesConstants'
import Divider from '~/components/shared/Divider'

/**
 *Name: MembershipCardMessages.js
 *Desc: Render MembershipCardMessages card content

 * @param {object} props
 * @returns Element
 */

const MembershipCardMessages = ({ list = [], heading = '', noMsgLabel }) => {
  const [activeId, setActiveId] = useState()

  const handleOnClick = (e) => {
    const { currentTarget } = e
    const { id } = currentTarget
    const isOpen = id === activeId

    if (!isOpen) {
      // If it's not open, don't instantly redirect the user
      e.preventDefault()
      setActiveId(isOpen ? undefined : id)
    }
    setActiveId(isOpen ? undefined : id)
  }

  return (
    <div
      className={styles.messageSection}
      id={HOME_PAGE_MESSAGE_ID}
      data-test="messages-tile"
    >
      {heading && <Heading as="h2" text={heading} className={styles.heading} />}
      {!list.length ? (
        <InfoMessage
          text={noMsgLabel}
          variant="xlgText"
          color="secondary"
          icon="check"
          dataTest="message-text"
        />
      ) : (
        <div className={styles.messageList}>
          <ScrollBar forceVisible="y" autoHide={false}>
            <div className={styles.pr15}>
              {list.map((data, index, all) => {
                const isLast = index === all.length - 1
                const activeClass =
                  activeId === data.id ? styles.active : styles.inactive
                return (
                  <div key={data.id}>
                    <Text
                      id={data.id}
                      cursor="pointer"
                      className={`${styles.clampedLine} ${styles.heightControl} ${activeClass}`}
                      fontWeight="strong"
                      fontFamily="md"
                      as="div"
                      onClick={handleOnClick}
                      text={purifyHtmlString(
                        data.messageText,
                        ALLOW_TARGET_BLANK
                      )}
                      data-test="message-tile-text"
                      variant="link"
                    />
                    {!isLast && <Divider m="10px 0" />}
                  </div>
                )
              })}
            </div>
          </ScrollBar>
        </div>
      )}
    </div>
  )
}

// Props Validation
MembershipCardMessages.propTypes = {
  heading: PropTypes.string,
  noMsgLabel: PropTypes.string,
  list: PropTypes.array,
}
export default MembershipCardMessages
