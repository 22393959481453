export default {
  en: {
    itemsYouMightLikeLabel: 'Items You Might Like',
    recentlyViewedItemsLabel: 'Recently Viewed Items',
  },
  fr: {
    itemsYouMightLikeLabel: 'Articles que vous pourriez aimer',
    recentlyViewedItemsLabel: 'Articles récemment consultés',
  },
}
