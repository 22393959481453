export default {
  en: {
    pageTitle: 'Order Confirmation',
    thisItemNotAvailableLabel: 'This item is not available in this quantity',
    itemHasBeenSuccessfullyLabel:
      'Item has been successfully added to the shopping list',
  },
  fr: {
    pageTitle: 'Confirmation de commande',
    thisItemNotAvailableLabel: 'This item is not available in this quantity',
    itemHasBeenSuccessfullyLabel:
      "L'article a été ajouté avec succès à la liste d'achats",
  },
}
