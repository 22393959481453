export default {
  en: {
    greeting: 'Hi',
    expenseAllowanceRemaining: 'Expense Allowance remaining',
  },
  fr: {
    greeting: 'Bonjour',
    expenseAllowanceRemaining: 'Allocation de dépenses restante',
  },
}
