import { sendRequest } from '~/api/apiHelper'
import { getOldApiUrl } from 'services/utils/configUtils'
import { getUrl } from '~/components/shared/EwayRouter'
import { SUBMITTED_ORDER_DETAILS } from '~/components/shared/EwayRouter/EwayRouterConstants'
export const OLD_API_URL = getOldApiUrl()

export async function rejectOrders(orderIds) {
  const url = `${OLD_API_URL}/api/orders/rejectSelectedOrders`
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(orderIds),
  }

  try {
    const result = await sendRequest(url, options)
    const { isSuccess } = result
    return { isSuccess, result }
  } catch (e) {
    return { isSuccess: false }
  }
}

export async function approveOrders(bodyParam) {
  const url = `${OLD_API_URL}/api/orders/approveSelectedOrders/`
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(bodyParam),
  }

  try {
    const result = await sendRequest(url, options)
    const { isSuccess } = result
    return { isSuccess, result }
  } catch (e) {
    return { isSuccess: false }
  }
}

export const getOrderDetailsUrl = (id, language, result) => {
  const showOrderDetailsUrl = getUrl(SUBMITTED_ORDER_DETAILS)
  if (!id) {
    id = '0000-0000'
  }
  if (result) {
    const {
      ewayOrderNo,
      isAvailableInAlpha,
      isOrderExists,
      orderId,
      originalNumber,
      postalCode,
    } = result
    const url = `${showOrderDetailsUrl}/${id?.trim()}?ewayOrderNo=${ewayOrderNo}&isAvailableInAlpha=${isAvailableInAlpha}&isOrderExists=${isOrderExists}&orderId=${orderId}&originalNumber=${originalNumber}&postalCode=${postalCode}`
    return url
  }
  const url = `${showOrderDetailsUrl}/${id?.trim()}`
  return url
}

/**
 * Expects an order id as a guid
 * The language (to format the response)
 * THe approval type, to get the order summary
 * @param  {...any} args
 */
export async function copyOrderToCart(...args) {
  const [language, orderId] = args
  const url = `${OLD_API_URL}/api/cart/copyToCart/${orderId}`
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': `${language}-CA`,
    },
  }
  try {
    const result = await sendRequest(url, options)
    const { isSuccess } = result
    return { isSuccess, result }
  } catch (e) {
    return { isSuccess: false }
  }
}
