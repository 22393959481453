import { addQueryString } from 'services/utils'
import { SEARCH_CALLING_LOCATION } from 'shared/AdobeAnalytics/AdobeAnalyticsConstants'
import CarouselArrow from 'shared/Carousel/CarouselArrow'
import { PAGE_NAMES } from 'shared/Utils/constants'
import { addCallingLocationToHref } from '~/components/shared/AdobeAnalytics/AdobeAnalyticsUtils'

export function getCarouselSettings(settings, language) {
  return {
    ...settings,
    nextArrow: <CarouselArrow as="button" type="button" language={language} />,
    prevArrow: <CarouselArrow as="button" type="button" language={language} />,
  }
}

export const appendQueryString = (url) => {
  return url
    ? `${addQueryString(
        url,
        addCallingLocationToHref(
          PAGE_NAMES.HOME,
          SEARCH_CALLING_LOCATION.BANNERS
        )
      )}`
    : ''
}

export function checkIsLoading(bannerImages = []) {
  return !(Array.isArray(bannerImages) && bannerImages.length)
}
