import classNames from 'classnames'
import PropTypes from 'prop-types'
import Text from 'shared/Text'

function DashBoardItemDescription({ className, ...props }) {
  const classes = classNames(className)
  return (
    <Text className={classes} variant="mdText" color="secondary" {...props} />
  )
}

DashBoardItemDescription.propTypes = {
  className: PropTypes.string,
}

export default DashBoardItemDescription
