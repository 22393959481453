import globalMessages from 'pageMessages/global.messages'
import { ENGLISH, FRENCH } from '~/redux/constants'

export default {
  en: {
    pageTitle: `${globalMessages[ENGLISH].siteTitle} Staples Professional Canada Online Store`,
  },
  fr: {
    pageTitle: `${globalMessages[FRENCH].siteTitle} Boutique en ligne Staples Professionnel Canada`,
  },
}
