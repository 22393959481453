export default {
  en: {
    singInHeading: 'Sign in with your User ID',
    singInSubHeading: 'Shop with existing user profile',
    continue: 'Continue',
    postalCode: 'Postal Code',
    postalCodeHelp: 'What postal code should I enter?',
    postalCodeHelpDetails:
      'Enter the postal code of the address where the order was shipped. The value you enter will be validated against the postal code provided when the order was submitted and the two must match to proceed.',
    requiredMessage: 'This field is mandatory.',
    signInMsg: 'Sign in',
    invalidPostalCode:
      ' The postal code entered is invalid.  Please ensure the postal code is entered using one of the following formats:   A1A1A1 OR A1A 1A1 (where A is a letter and 1 is a digit).',
    createProfileHeading: 'Create Profile',
    createProfileSubHeading:
      'Shop as a first time user and create a new profile during checkout',
    shopNow: 'Shop now',
    errorShopNowLogin:
      'There was an error authenticating your login attempt due to a missing or invalid User ID configuration.  Please reach out to your Account Manager for assistance.',
  },
  fr: {
    singInHeading: ' Connectez-vous avec votre nom d’utilisateur',
    singInSubHeading: `Magasinez avec un profil d'utilisateur existant`,
    continue: 'Continuer',
    postalCode: 'Code postal',
    postalCodeHelp: 'Quel code postal dois-je saisir ?',
    postalCodeHelpDetails:
      "Entrez le code postal de l'adresse où la commande a été expédiée. La valeur que vous entrez sera validée par rapport au code postal fourni lorsque la commande a été soumise et les deux doivent correspondre pour continuer.",
    requiredMessage: 'Veuillez entrer une valeur.',
    signInMsg: 'Ouvrir une session',
    invalidPostalCode:
      " Le code postal saisi n'est pas valide. Veuillez-vous assurer que le code postal est entré en utilisant l'un des formats suivants : A1A1A1 OU A1A 1A1 (où A est une lettre et 1 est un chiffre).",
    createProfileHeading: 'Créer un profil',
    createProfileSubHeading: `Magasinez en tant que premier utilisateur et créez un nouveau profil en passant à la caisse`,
    shopNow: 'Magasinez maintenant',
    errorShopNowLogin: `Une erreur s'est produite lors de l'authentification de votre connexion en raison d'une configuration d'identifiant utilisateur manquante ou non valide. Veuillez contacter votre gestionnaire de compte pour obtenir de l'aide.`,
  },
}
