import PropTypes from 'prop-types'
import Image from 'shared/Image'
import { getHostUrl } from 'services/utils/configUtils'
import styles from './PromotionCard.module.scss'

const HOST_URL = getHostUrl()

/**
 *Name: PromotionCard.js
 *Desc: Render promo banner content

* @param {string} imageName
* @param {string} link
* @param {string} language
* @param {string} promoName
* @returns Element
 */
const PromotionCard = ({ imageName, link, language, promoName }) => {
  return (
    <div className={styles.promotion} data-test="promotion-card">
      {imageName && (
        <div className={styles.productImg}>
          <Image
            src={imageName}
            alt={promoName}
            title={promoName}
            link={link}
            defaultImage={`${HOST_URL}/Images/PromoImages/${language}/filler_medium.jpg`}
          />
        </div>
      )}
    </div>
  )
}

// Props Validation
PromotionCard.propTypes = {
  imageName: PropTypes.string,
  link: PropTypes.string,
  language: PropTypes.string,
  promoName: PropTypes.string,
}
export default PromotionCard
