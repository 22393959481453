import PropTypes from 'prop-types'
import styles from './HomePageCard.module.scss'

/**
 *Name: Card.js
 *Desc: Render Card item content
 * @param {*} height
 * @param {*} children
 * @returns Element
 */

function HomePageCard({ height, children }) {
  const elementHeight = height ? styles.height : ''
  return <div className={`${styles.card} ${elementHeight}`}>{children}</div>
}

// Props Validation
HomePageCard.propTypes = {
  height: PropTypes.string,
  children: PropTypes.element.isRequired,
}

export default HomePageCard
