import Price from 'components/shared/Price/Price'
import PropTypes from 'prop-types'
import Text from 'shared/Text'
import Box from '~/components/shared/Box'
import Greeting from '~/components/shared/Dashboard/DashboardGreeting'
import DashBoardItemDescription from '~/components/shared/Dashboard/DashBoardItemDescription'
import DashBoardItemTitle from '~/components/shared/Dashboard/DashBoardItemTitle'
import Divider from '~/components/shared/Divider'
import Flex from '~/components/shared/Flex'
import InfoTooltip from '~/components/shared/InfoTooltip'
import { ENGLISH } from '~/redux/constants'
import styles from './MembershipDetail.module.scss'
import messages from './messages'

/**
 * Name: MembershipDetail
 * Desc: Show information related to user membership
 * @param {string} language
 * @param {object} userInfo
 */

const MembershipDetail = ({ language = ENGLISH, userInfo = {} }) => {
  return (
    <div className={styles.membershipDetail}>
      <Flex className={styles.columnForMobile}>
        <div className={styles.flexItem}>
          <Greeting name={userInfo.name} language={language} />
        </div>
        <div className={styles.flexItem}>
          <DashBoardItemTitle>{userInfo.programName}</DashBoardItemTitle>
          <DashBoardItemDescription>{`${messages[language].renewalDateLabel}: ${userInfo.renewalDate}`}</DashBoardItemDescription>
        </div>
        <div className={styles.flexItem}>
          <DashBoardItemTitle>
            <Price language={language}>{userInfo.annualTotalSavings}</Price>
          </DashBoardItemTitle>
          <DashBoardItemDescription>
            <Flex>
              <span>{messages[language].annualCompanySavingsLabel} </span>
              <InfoTooltip
                as="span"
                ml="5px"
                place="bottom"
                align="center"
                offset={{ top: 10 }}
                id="ship-to-selection-question-tooltip"
                className={styles.annualCompanySavingsToolTip}
                text={
                  <Box fontSize="13px" color="lightGray">
                    <Box mb="5px">
                      <Text fontFamily="md" className={styles.tooltipHeading}>
                        {messages[language].annualCompSavBreakdownLabel}
                      </Text>
                    </Box>
                    <Box mb="5px">
                      {messages[language].annualPremiumSavingsLabel}
                      <Box pl="3px" as="span">
                        <Price language={language}>
                          {userInfo.annualMembershipSavings}
                        </Price>
                      </Box>
                    </Box>
                    <Box mb="5px">
                      {messages[language].annualContSavingLabel}
                      <Box pl="3px" as="span">
                        <Price language={language}>
                          {userInfo.annualContractSavings}
                        </Price>
                      </Box>
                    </Box>
                    <Divider h="2px" mt="10px" mb="10px" />
                    <div>{messages[language].annualDescLabel}</div>
                  </Box>
                }
              />
            </Flex>
          </DashBoardItemDescription>
        </div>
        <div className={styles.flexItem}>
          <span dangerouslySetInnerHTML={{ __html: userInfo.benefit }} />
        </div>
      </Flex>
    </div>
  )
}

// Props Validation
MembershipDetail.propTypes = {
  language: PropTypes.string,
  userInfo: PropTypes.object.isRequired,
}

export default MembershipDetail
