import CardVariants from '~/components/containers/Home/MembershipCardPanel/MembershipVariants'
import PropTypes from 'prop-types'
import { budgetCalculatorDateIsInvalid } from 'services/budgetServices'
import HomePageCard from '~/components/shared/HomePageCard'
import Carousel from 'shared/Carousel'
import {
  APPROVALS,
  BUDGET_CALCULATOR,
  NOTIFICATION,
  ORDER,
  SHOPPING_LIST,
} from '~/components/containers/Home/MembershipCardPanel/MembershipVariants/MembershipVariantsConstants'
import Container from '~/components/shared/Container'
import styles from './MembershipCardPanel.module.scss'
import { carouselSettings } from './MembershipCardPanelUtils'

/**
 *Name: MembershipCardPanel.js
 *Desc: Render MembershipCardPanel page content

 * @param {*} language
 * @param {*} settings
 * @param {object} budgetCalculatorData
 * @param {object} orderData
 * @param {object} orderApprovalData
 * @param {object} listData
 * @param {object} messagesData
 * @param {bool} isApprover
 * @returns Element
 */

const MembershipCardPanel = ({
  language,
  settings,
  budgetCalculatorData,
  orderData,
  orderApprovalData,
  listData,
  messagesData,
  isApprover,
}) => {
  const cardItems = [
    {
      cardType: NOTIFICATION,
      cardData: {
        ...messagesData,
      },
    },
    {
      cardType: APPROVALS,
      cardData: {
        ...orderApprovalData,
      },
    },

    {
      cardType: BUDGET_CALCULATOR,
      cardData: {
        ...budgetCalculatorData,
      },
    },
    {
      cardType: ORDER,
      cardData: { ...orderData },
    },
    {
      cardType: SHOPPING_LIST,
      cardData: { ...listData },
    },
  ]

  if (!budgetCalculatorData?.showBudgetCalculator) {
    cardItems.splice(
      cardItems.findIndex((item) => item.cardType === BUDGET_CALCULATOR),
      1
    )
  }

  if (budgetCalculatorDateIsInvalid(budgetCalculatorData || {})) {
    cardItems.splice(
      cardItems.findIndex((item) => item.cardType === BUDGET_CALCULATOR),
      1
    )
  }

  if (!settings?.displayEwayMessagingCenter) {
    cardItems.splice(
      cardItems.findIndex((item) => item.cardType === NOTIFICATION),
      1
    )
  }

  if (!settings?.showShoppingListsMenu) {
    cardItems.splice(
      cardItems.findIndex((item) => item.cardType === SHOPPING_LIST),
      1
    )
  }

  if (!settings?.showMenuOrder || !isApprover) {
    cardItems.splice(
      cardItems.findIndex((item) => item.cardType === APPROVALS),
      1
    )
  }
  if (!settings?.showMenuOrder || !settings.showMenuOrderSubmitted) {
    cardItems.splice(
      cardItems.findIndex((item) => item.cardType === ORDER),
      1
    )
  }

  return cardItems.length ? (
    <section
      className={styles.membershipCardPanel}
      data-test="membership-dashboard"
    >
      <Container>
        <div className={styles.mainCardWrapper}>
          <Carousel {...carouselSettings}>
            {cardItems.map((cardItem) => {
              return (
                <div className={styles.carouselItems} key={cardItem.cardType}>
                  <HomePageCard height="sm">
                    <CardVariants {...cardItem} language={language} />
                  </HomePageCard>
                </div>
              )
            })}
          </Carousel>
        </div>
      </Container>
    </section>
  ) : null
}

// Default Props
MembershipCardPanel.defaultProps = {
  budgetCalculatorData: {},
}

// PropType Validation
MembershipCardPanel.propTypes = {
  settings: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  budgetCalculatorData: PropTypes.object,
  orderData: PropTypes.object,
  orderApprovalData: PropTypes.object,
  listData: PropTypes.object,
  messagesData: PropTypes.object,
  isApprover: PropTypes.bool,
}
export default MembershipCardPanel
