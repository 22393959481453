import PropTypes from 'prop-types'
import styles from './SiteContent.module.scss'
import classNames from 'classnames'

/**
 * Name: SiteContent
 * Decs: Render sitecontent
 * @param {String} cmsContent
 */

const SiteContent = ({
  cmsContent,
  padding,
  listType,
  greyText,
  customStyle,
  className,
}) => {
  if (!cmsContent) {
    return null
  }
  const myClasses = classNames(
    styles.siteContent,
    className,
    padding ? styles[padding] : '',
    listType ? styles[listType] : '',
    (customStyle = customStyle || ''),
    greyText
  )

  return (
    <section className={myClasses}>
      <div className={`${styles.container} ${customStyle}`}>
        <div dangerouslySetInnerHTML={{ __html: cmsContent }} />
      </div>
      {/* Eventually, the CMS will have the styling, temporary, eventually to remove */}
      <CmsContentStyling />
    </section>
  )
}
SiteContent.propTypes = {
  cmsContent: PropTypes.string.isRequired,
  padding: PropTypes.string,
  listType: PropTypes.string,
  greyText: PropTypes.string,
  className: PropTypes.string,
  customStyle: PropTypes.node,
}

export default SiteContent

function CmsContentStyling() {
  return (
    <style jsx="true" global="true">{`
      #container_lob .clear {
        margin-right: calc(100% - 600px);
      }

      #container_lob {
        display: flex;
        flex-wrap: wrap;
      }

      #container_lob > *:first-child {
        flex-basis: 100%;
        flex: 0 0 100%;
      }

      #container_lob .lob_mod {
        padding-right: 30px;
        padding-bottom: 30px;
      }
    `}</style>
  )
}
