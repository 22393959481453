import CarouselArrow from 'shared/Carousel/CarouselArrow'
import { BREAK_POINTS } from 'shared/Utils/constants'
export const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: false,
  nextArrow: <CarouselArrow variant="small" />,
  prevArrow: <CarouselArrow variant="small" />,

  responsive: [
    {
      breakpoint: BREAK_POINTS.md,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        swipeToSlide: true,
        arrows: false,
        dotsClass: 'slick-dots primaryDots',
      },
    },
    {
      breakpoint: BREAK_POINTS.sm + 1,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        swipeToSlide: true,
        arrows: false,
        dotsClass: 'slick-dots primaryDots',
      },
    },
    {
      breakpoint: BREAK_POINTS.xs,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        swipeToSlide: true,
        arrows: false,
        dotsClass: 'slick-dots primaryDots',
      },
    },
  ],
}
