import { SHOW_NOW_PRE_LOGIN_QUERY } from './ShopNowPreLoginConstant'
export function getShowNowPreLoginData(query) {
  const lowerCasedKeysQuery = Object.entries(query)
    .map(([key, value]) => [String(key).toLowerCase(), value])
    .reduce((lastMerged, [key, value]) => ({ ...lastMerged, [key]: value }), {})
  const clientId = lowerCasedKeysQuery[SHOW_NOW_PRE_LOGIN_QUERY.CLIENT_ID]
  const signIn = lowerCasedKeysQuery[SHOW_NOW_PRE_LOGIN_QUERY.SIGN_IN]
  const showShopNowPreLogin = clientId && signIn === 'true'
  const showPreLoginPageDetail = !!clientId
  return { showShopNowPreLogin, clientId, showPreLoginPageDetail }
}
