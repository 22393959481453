import PropTypes from 'prop-types'
import { generateArrayByLength } from 'services/utils/utils'
import Card from '~/components/shared/HomePageCard'
import PromotionCard from '~/components/containers/Home/PromotionalProducts/PromotionCard'
import Carousel from 'shared/Carousel'
import styles from './PromotionalProducts.module.scss'
import PromotionalProductsSkeleton from './PromotionalProductsSkeleton'
import Container from '~/components/shared/Container'
import { settings } from './PromotionalProductsUtils'

/**
 * Name: PromotionalProducts
 * Desc: Render Promotional products
 * @param {array} products
 * @param {string} language
 * @param {bool} showSkeleton
 * @returns Element
 */

const PromotionalProducts = ({ products, language, showSkeleton }) => (
  <section className={styles.promotionCardWrapper}>
    <Container>
      <div className={styles.mainCardWrapper} id="promotions">
        <Carousel {...settings}>
          {!showSkeleton
            ? products.map((product, index) => (
                <div className={styles.carouselItems} key={index}>
                  <Card height="sm">
                    <PromotionCard
                      key={index}
                      {...product}
                      language={language}
                    />
                  </Card>
                </div>
              ))
            : generateArrayByLength(3).map((item) => (
                <PromotionalProductsSkeleton key={item} />
              ))}
        </Carousel>
      </div>
    </Container>
  </section>
)

// Props Validation
PromotionalProducts.propTypes = {
  products: PropTypes.array.isRequired,
  language: PropTypes.string,
  showSkeleton: PropTypes.bool,
}

export default PromotionalProducts
