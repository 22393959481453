export default {
  en: {
    caretOpenLabel: 'Caret open',
    caretDownLabel: 'Caret down',
  },
  fr: {
    caretOpenLabel: 'caret ouvert',
    caretDownLabel: 'descendre',
  },
}
