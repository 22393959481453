export default {
  en: {
    categorySavingsLabel: 'Category Savings:',
    businessEssentialsLabel: 'Business Essentials',
    facilitiesLabel: 'Facilities',
    furnitureLabel: 'Furniture',
    printPromoLabel: 'Print / Promo',
    techLabel: 'Tech',
    premiumLabel: 'Premium:',
    contractLabel: 'Contract:',
  },
  fr: {
    categorySavingsLabel: 'Épargnes par catégorie :',
    businessEssentialsLabel: 'Essentiels bureau',
    facilitiesLabel: 'Entretien',
    furnitureLabel: 'Meubles',
    printPromoLabel: 'Impression / Promo',
    techLabel: 'Tech',
    premiumLabel: 'Premium :',
    contractLabel: 'contrat :',
  },
}
