import PropTypes from 'prop-types'
import Carousel from 'shared/Carousel'
import Image from 'shared/Image'
import Container from '../Container'
import styles from './Banner.module.scss'
import { carouselSettings as carousel } from './BannerConstants'
import BannerLoader from './BannerSkeleton.js'
import {
  appendQueryString,
  getCarouselSettings,
  checkIsLoading,
} from './BannerUtils'
import messages from './messages'

/**
 *Name: Banner
 *Desc: Render banner with images
 * @param {array} bannerImages
 * @param {object} carouselSettings
 * @param {string} language
 * @returns Carousel Element
 */
const Banner = ({ bannerImages = [], language }) => {
  const carouselSettings = getCarouselSettings(carousel, language)
  // TODO: "checkIsLoading" should be a boolean from parent named "isLoading" ⬇️
  const isLoading = checkIsLoading(bannerImages)
  return (
    <Container className={styles.containerMd}>
      <div className={styles.bannerWrapper}>
        {isLoading ? (
          <div className={styles.skeletonBanner}>
            <BannerLoader title={messages[language].bannerText} />
          </div>
        ) : (
          <Carousel {...carouselSettings}>
            {bannerImages.map((data, index) => (
              <div className={styles.imgItem} key={index}>
                <Image
                  src={data.ImageUrl}
                  alt={data.ImageTitle || messages[language].bannerText}
                  title={messages[language].bannerText}
                  link={appendQueryString(data.TargetUrl)}
                  target={!data.OpenSamePage ? '_blank' : ''}
                />
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </Container>
  )
}

// PropType validation
Banner.propTypes = {
  bannerImages: PropTypes.array.isRequired,
  language: PropTypes.string.isRequired,
}

export default Banner
