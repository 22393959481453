import EwayLink from 'components/shared/EwayLink'
import PropTypes from 'prop-types'
import Image from 'shared/Image'
import Text from 'shared/Text'
import Flex from '~/components/shared/Flex'
import Icon from '~/components/shared/Icon'
import Stack from '~/components/shared/Stack'
import styles from './MembershipShoppingListItem.module.scss'

/**
 *Name: MembershipShoppingListItem
 *Desc: Render MembershipShopping List Item Element

 * @param {*} count
 * @param {*} countLabel
 * @param {*} href
 * @param {*} src
 * @param {*} showPlusSign
 * @param {*} title
 * @param {*} listUrl
 * @returns Element
 */

const MembershipShoppingListItem = ({
  count,
  countLabel,
  href,
  src,
  showPlusSign,
  title,
}) => {
  return (
    <Flex
      data-test="membership-shopping-list-item"
      direction="column"
      align="center"
      textAlign="center"
      className={styles.cardListDetails}
      listStyle="none"
      as="li"
    >
      <Text
        text={title}
        variant="mdText"
        color="secondary"
        className={styles.label}
        data-test="membership-item-title"
      />
      <Flex align="center" justify="center" className={styles.wrapper}>
        <EwayLink href={href} aria-label={title}>
          {showPlusSign ? (
            <Icon variant="plus" className={styles.plus} />
          ) : src ? (
            <Image
              src={src}
              alt={title}
              title={title}
              className={styles.image}
              width="80"
              height="80"
              data-test="membership-item-image"
            />
          ) : (
            <Icon
              variant="image"
              className={styles.fallbackImage}
              data-test="membership-item-fallback-image"
            />
          )}
        </EwayLink>
      </Flex>
      {count ? (
        <Stack spacing="3px" data-test="membership-item-count" mt="5px">
          <Text
            text={count}
            color="secondary"
            data-test="membership-item-count-number"
          />
          <Text
            text={countLabel}
            color="secondary"
            data-test="membership-item-count-label"
          />
        </Stack>
      ) : null}
    </Flex>
  )
}

// Props Validation
MembershipShoppingListItem.propTypes = {
  showPlusSign: PropTypes.bool,
  title: PropTypes.string,
  src: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  href: PropTypes.string,
  countLabel: PropTypes.string,
}
export default MembershipShoppingListItem
