import PropTypes from 'prop-types'
import Heading from 'shared/Heading'
import Text from 'shared/Text'
import Price from 'shared/Price/Price'
import { customDateFormat } from 'services/utils'
import styles from './MembershipBudgetCalculator.module.scss'

/**
 *Name: MembershipBudgetCalculator
 *Desc: MembershipBudgetCalculator tile content

 * @param {string} heading
 * @param {string} budgetLabel
 * @param {number} totalBudget
 * @param {bool} showCustomDates
 * @param {string} beginDate
 * @param {string} endDate
 * @param {string} budgetTimePeriodLabel
 * @param {string} language
 * @param {string} budgetAvailableLabel
 * @param {number} AvailableBudget
 * @param {string} toLabel
 * @param {bool} showTimePeriodDates
 * @returns Element
 */

function MembershipBudgetCalculator({
  heading,
  budgetLabel,
  totalBudget,
  budgetTimePeriodLabel,
  showCustomDates,
  beginDate,
  endDate,
  language,
  budgetAvailableLabel,
  availableBudget,
  toLabel,
  showTimePeriodDates,
}) {
  const date = `${customDateFormat(beginDate)} ${toLabel} ${customDateFormat(
    endDate
  )}`
  return (
    <div className={styles.budgetCalculator}>
      {!!heading && <Heading as="h2" text={heading} />}

      <div className={styles.budgetContent}>
        <div className={styles.colRow}>
          <div className={styles.colLeft}>
            <Text
              text={budgetLabel}
              variant="smText"
              fontWeight="strong"
              color="secondary"
            />
            <Heading
              as="h3"
              color="primary"
              text={<Price language={language}>{totalBudget}</Price>}
            />
          </div>
          {showCustomDates && showTimePeriodDates && (
            <div className={styles.colRight}>
              <Text
                text={budgetTimePeriodLabel}
                variant="smText"
                fontWeight="strong"
                color="secondary"
              />
              <Heading as="h5" color="primary" text={date} />
            </div>
          )}
        </div>
      </div>
      <div className={styles.budgetContent}>
        <Text
          text={budgetAvailableLabel}
          variant="smText"
          fontWeight="strong"
          color="secondary"
        />
        <Heading
          as="h3"
          color="primary"
          text={<Price language={language}>{availableBudget}</Price>}
        />
      </div>
    </div>
  )
}

// Props Validation
MembershipBudgetCalculator.propTypes = {
  heading: PropTypes.string,
  budgetLabel: PropTypes.string,
  totalBudget: PropTypes.number,
  showCustomDates: PropTypes.bool,
  budgetTimePeriodLabel: PropTypes.string,
  beginDate: PropTypes.string,
  endDate: PropTypes.string,
  language: PropTypes.string,
  budgetAvailableLabel: PropTypes.string,
  availableBudget: PropTypes.number,
  toLabel: PropTypes.string,
  showTimePeriodDates: PropTypes.bool,
}

export default MembershipBudgetCalculator
