import PropTypes from 'prop-types'
import classNames from 'classnames'
import Heading from 'shared/Heading'
import Flex from 'shared/Flex'
import Box from 'shared/Box'
import Text from 'shared/Text'
import SearchBox from 'shared/SearchBox'
import EwayLink from '~/components/shared/EwayLink'
import Table from 'shared/Table'
import InfoMessage from '~/components/shared/InfoMessage'
import { useOrderSearch, getCardType } from './membershipOrderUtils'
import { getUrl } from '~/components/shared/EwayRouter'
import { FIND_YOUR_ORDER } from '~/components/shared/EwayRouter/EwayRouterConstants'
import DynamicTranslation from '~/components/shared/DynamicTranslation'
import messages from '../../messages'
import styles from './MembershipOrder.module.scss'
import { ENGLISH } from '~/redux/constants'

/**
 *Name: MembershipOrder
 *Desc: Render order card content

* @param {*} cardType
* @param {*} orderCount
* @param {*} noOrderLabel
* @param {*} noApprovalOrderLabel
* @param {*} heading
* @param {*} youHaveLabel
* @param {*} orderLabel
* @param {*} ordersLabel
* @param {*} waitingApprovalLabel
* @param {*} orderHeaderRow
* @param {array} orderData
* @param {*} language
* @param {*} viewOrderLabel
* @param {*} viewOrderUrl
* @returns Element
 */

function MembershipOrder({
  cardType,
  orderCount,
  noOrderLabel,
  noApprovalOrderLabel,
  heading,
  youHaveLabel,
  moreOrderLabel,
  waitingApprovalLabel,
  orderHeaderRow,
  orderData = [],
  language = ENGLISH,
  viewOrderLabel,
  viewOrderUrl,
  buyerId = '',
}) {
  const {
    orders,
    handleOnSearch,
    isActiveSearch,
    searchResultMsg,
    orderTableRows,
  } = useOrderSearch(language, buyerId, orderData)

  const { isOrderCardTile, isApprovalsCardTile } = getCardType(cardType)
  const displayText = isOrderCardTile ? noOrderLabel : noApprovalOrderLabel
  const showSearchBox = isOrderCardTile

  const { searchPlaceholder, findYourOrderDetails, findYourOrder, search } =
    messages[language]
  const headingWrapperStyle = classNames({
    [`${styles.approvalHeadingWrapper}`]: isApprovalsCardTile,
  })
  const findYourOrderUrl = getUrl(FIND_YOUR_ORDER)
  const findYourOrderLink = (
    <EwayLink
      data-test="find-your-order-link"
      text={findYourOrder}
      href={findYourOrderUrl}
      textDecoration={true}
      fontFamily="md"
    />
  )
  const showOrderList = orderCount > 0 || isActiveSearch
  const showOrderMessage = !orderCount && !isActiveSearch
  const btnProps = {
    'aria-label': search,
  }
  const orderListClass = isOrderCardTile ? styles.orderListTable : null
  return (
    <div className={styles.orderSection}>
      <div className={styles.flex}>
        <Flex
          justify="space-between"
          align="center"
          className={headingWrapperStyle}
        >
          {!!heading && <Heading as="h2" text={heading} />}
          {orderCount > 0 ? (
            <EwayLink
              data-test="view-all-orders"
              text={viewOrderLabel}
              href={viewOrderUrl}
              textDecoration={true}
              fontFamily="md"
            />
          ) : null}
        </Flex>
        {showSearchBox ? (
          <Box m="10px 0" className={`${styles.search}`}>
            <SearchBox
              data-test="order-search-filter"
              name="order-search-field"
              id="order-search-field"
              aria-label="order-search-field"
              placeholder={searchPlaceholder}
              onChange={handleOnSearch}
              btnProps={btnProps}
            />
          </Box>
        ) : null}

        {showOrderMessage ? (
          <InfoMessage
            text={displayText}
            variant="xlgText"
            color="secondary"
            icon="check"
            dataTest={displayText}
          />
        ) : null}

        {showOrderList ? (
          <div className={styles.orderContent}>
            {isOrderCardTile && !isActiveSearch ? (
              <>
                <Text color="secondary" variant="xlgText" text={youHaveLabel} />
                <Text
                  variant="xlgText"
                  text={orderCount}
                  color="primary"
                  fontWeight="strong"
                />

                <Text
                  color="secondary"
                  variant="xlgText"
                  text={moreOrderLabel}
                />
              </>
            ) : null}
            {isActiveSearch ? (
              <Text
                text={searchResultMsg}
                color="secondary"
                variant="xlgText"
                data-test="search-order-msg"
              />
            ) : null}

            {isApprovalsCardTile ? (
              <>
                <Text color="secondary" variant="xlgText" text={youHaveLabel} />
                <Text
                  variant="xlgText"
                  text={orderCount}
                  color="primary"
                  fontWeight="strong"
                />
                <Text
                  color="secondary"
                  variant="xlgText"
                  text={waitingApprovalLabel}
                />
              </>
            ) : null}

            <div className={styles.contentTable}>
              {orders?.length > 0 ? (
                <Table
                  columns={orderHeaderRow}
                  rows={orderTableRows}
                  noHeaderBackground={true}
                  variant="tableSm"
                  fontWeight="strong"
                  variantText="mdText"
                  color="secondary"
                  language={language}
                  ellipsis="ellipsis"
                  scrollClassName={
                    isOrderCardTile ? styles.memberShipOrderTableScroll : ''
                  }
                  className={orderListClass}
                />
              ) : (
                <Box mt="10px">
                  <DynamicTranslation
                    text={findYourOrderDetails}
                    replace="{FIND_YOUR_ORDER}"
                    with={findYourOrderLink}
                  />
                </Box>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

// Props Validation
MembershipOrder.propTypes = {
  cardType: PropTypes.string.isRequired,
  orderCount: PropTypes.number,
  noOrderLabel: PropTypes.string,
  noApprovalOrderLabel: PropTypes.string,
  heading: PropTypes.string,
  youHaveLabel: PropTypes.string,
  moreOrderLabel: PropTypes.string,
  waitingApprovalLabel: PropTypes.string,
  orderHeaderRow: PropTypes.array,
  orderData: PropTypes.array,
  language: PropTypes.string,
  viewOrderLabel: PropTypes.string,
  viewOrderUrl: PropTypes.string,
  buyerId: PropTypes.string,
}

export default MembershipOrder
