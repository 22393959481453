import { sendRequest } from '~/api/apiHelper'
import { getApiUrl } from 'services/utils/configUtils'

const API_URL = getApiUrl()

export const ssoLogin = async ({ logonName, clientId } = {}) => {
  if (!clientId || !logonName) {
    return errorResponse('Missing arguments, logonName, or clientId')
  }
  const url = `${API_URL}/session/sso-login-for/${logonName}?clientId=${clientId}`
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    const { isSuccess, status, redirectUrl } = await sendRequest(url, options)

    return Promise.resolve({ isSuccess, status, redirectUrl })
  } catch (e) {
    return errorResponse(e)
  }
}

function errorResponse(message) {
  return Promise.resolve({
    isSuccess: false,
    status: 400,
    message,
    redirectUrl: '',
  })
}
