import ContentLoader from 'react-content-loader'

const PromotionalProductsSkeleton = () => (
  <ContentLoader
    viewBox="0 0 452 268"
    backgroundColor={'#fff'}
    foregroundOpacity={0.06}
    uniqueKey="promotionalProductsSkeleton"
  >
    <rect x="8" y="0" rx="0" ry="0" width="452" height="268" />
  </ContentLoader>
)

export default PromotionalProductsSkeleton
