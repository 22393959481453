import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

const MembershipCardPanelLoader = ({ title }) => {
  return (
    <ContentLoader
      style={{ width: '100%' }}
      title={title}
      uniqueKey="membershipCardPanelLoader"
    >
      <rect x="5" y="0" rx="0" ry="0" width="180" height="40" />
      <rect x="5" y="70" rx="0" ry="0" width="420" height="20" />
    </ContentLoader>
  )
}

// PropType validation
MembershipCardPanelLoader.propTypes = {
  title: PropTypes.string.isRequired,
}

export default MembershipCardPanelLoader
