import PropTypes from 'prop-types'
import Box from '~/components/shared/Box'
import DashboardGreeting from '~/components/shared/Dashboard/DashboardGreeting'
import DashBoardItemDescription from '~/components/shared/Dashboard/DashBoardItemDescription'
import DashBoardItemTitle from '~/components/shared/Dashboard/DashBoardItemTitle'
import Price from '~/components/shared/Price'
import Stack from '~/components/shared/Stack'
import { ENGLISH } from '~/redux/constants'
import styles from './AllowanceDashboard.module.scss'
import AllowanceDashboardSkeleton from './AllowanceDashboardSkeleton'
import { formatAllowanceMessages } from './AllowanceDashboardUtils/AllowanceDashboardUtils'
import messages from './messages'

const AllowanceDashboard = ({
  firstName,
  total,
  language = ENGLISH,
  remainingAmount,
  showSkeleton,
}) => {
  const translations = messages[language]
  const { paragraphOne, paragraphTwo, paragraphThree } =
    formatAllowanceMessages(total, language)
  return (
    <>
      {showSkeleton ? (
        <AllowanceDashboardSkeleton />
      ) : (
        <Box p="20px">
          <Stack className={styles.allowanceDashboard} spacing="20px">
            <DashboardGreeting
              name={firstName}
              language={language}
              data-test="allowance-dashboard-first-name"
            />
            <Box>
              <DashBoardItemTitle data-test="allowance-dashboard-remaining-amount">
                <Price language={language}>{remainingAmount}</Price>
              </DashBoardItemTitle>
              <DashBoardItemDescription>
                {translations.expenseAllowanceRemaining}
              </DashBoardItemDescription>
            </Box>
            <Box data-test="allowance-dashboard-explanation">
              <Box mb="10px">{paragraphOne}</Box>
              <Box mb="10px">{paragraphTwo}</Box>
              <Box>{paragraphThree}</Box>
            </Box>
          </Stack>
        </Box>
      )}
    </>
  )
}

AllowanceDashboard.propTypes = {
  firstName: PropTypes.string,
  language: PropTypes.string,
  showSkeleton: PropTypes.bool,
  total: PropTypes.number,
  remainingAmount: PropTypes.number,
}

export default AllowanceDashboard
