import {
  fetchRecentAndRecommendationProducts,
  RECOMMENDATION_TYPE,
} from 'api/recommendations'
import Home from 'components/containers/Home'
import { useRouter, withRouter } from 'next/router'
import pageMessages from 'pageMessages/home.messages'
import messages from 'pageMessages/index.messages'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState, useRef } from 'react'
import { languageConnector } from '@redux/connector'
import { ENGLISH } from '@redux/constants'
import {
  fetchContentData,
  HOME_PAGE_CONTENT,
} from 'services/customContentService'
import { fetchMembershipDetail } from 'services/membershipService'
import { cardOrderApprovalData, cardOrderData } from 'services/orderService'
import { cardListData } from 'services/shoppingListService'
import { checkKeysEmpty } from 'services/utils'
import {
  showDeletedItemsAlerts,
  showShipToChangeAdditionalMessage,
} from 'shared/Alerts/AlertNotificationContainer/AlertNotificationContainerUtils'
import {
  QUICK_VIEW_CONTAINER_ID,
  QUICK_VIEW_MESSAGE_ID,
} from 'shared/Alerts/AlertNotificationUtils/AlertNotificationConstants'
import { filterAllMessage } from 'shared/Alerts/AlertNotificationUtils/AlertNotificationFilteringUtils'
import { closeAllNotification } from 'shared/Alerts/AlertNotificationUtils/AlertNotificationUtils'
import { PRODUCT_QUICK_VIEW } from 'shared/Dialog/constants'
import { isDialogOpen } from 'shared/Dialog/DialogUtils'
import { PAGE_NAMES } from 'shared/Utils/constants'
import { queryParamsToProps } from 'shared/Utils/pageUtils'
import { useAllowance } from '~/components/containers/Home/AllowanceDashboard/AllowanceDashboardUtils/AllowanceDashboardUtils'
import { cardBudgetData } from '~/components/containers/Home/HomeUtils'
import { getShowNowPreLoginData } from '~/components/containers/Home/ShopNowPreLogin/ShopNowPreLoginUtils'
import {
  showApiNotifications,
  showApprovalAlerts,
  showPromotionalAlerts,
} from '~/components/shared/Alerts/AlertNotificationUtils/AlertNotificationUIUtils'
import { handleSuspensionMessage } from '~/components/shared/Alerts/Types/SuspendedMessage/SuspendedMessageUtils'
import { getUrl } from '~/components/shared/EwayRouter'
import { HOME_ROUTE } from '~/components/shared/EwayRouter/EwayRouterConstants'
import Layout from '~/components/shared/Layout'
import {
  isLoggedIn,
  recommendationsIsEnabled,
} from '~/components/shared/Utils/userUtils'
import { onAddToCartResponse } from '~/services/addToCartService'
import {
  fetchHomePageDefaultData,
  sendBloomReachHomeAnalytics,
} from '~/services/homeService'
import {
  fetchPromotionBanners,
  fetchNewPromotionBanners,
} from '~/services/promotionBannerService'
import {
  setSessionStorage,
  getSessionStorage,
  removeSessionStorage,
} from '~/services/storageManager'
import { CLIENT_ID } from '~/components/containers/Home/constants'
import { getNewPromotionsFlag } from 'services/utils/configUtils'

const enableNewPromotions = getNewPromotionsFlag()

function HomePage(props) {
  const [state, setState] = useState(defaultState)
  const promotionsData = useRef({})
  const {
    buyer = {},
    language = ENGLISH,
    details = {},
    settings = {},
    order = {},
  } = props
  const {
    showMenuOrder,
    showMenuOrderSubmitted,
    showCustomDates,
    siteContentNo,
  } = settings
  const { buyerId, canViewExpenseAllowanceCalculator } = buyer
  const { enablePwgscheckout, enableSalesrepContactDisplay = false } = settings
  const { emailAccountRep, suspendedDateFrom, suspendedDateUntil, clientNo } =
    details
  const isApprover = buyer ? buyer.isApprover : false
  const loggedIn = isLoggedIn(buyer)
  const recommendationsEnabled =
    loggedIn && recommendationsIsEnabled(props.settings)
  const startRecommendationFetch = loggedIn && recommendationsEnabled
  const email = enableSalesrepContactDisplay ? emailAccountRep : ''
  const { tokenHeader } = props

  const displayAllowance = loggedIn && canViewExpenseAllowanceCalculator
  const allowance = useAllowance({
    canFetchAllowance: displayAllowance,
    email: buyer.email,
    clientNo,
    language,
  })

  const { query = {} } = useRouter()

  const { clientId = '', showPreLoginPageDetail = false } =
    getShowNowPreLoginData(query)

  const getProductList = useCallback(async () => {
    if (startRecommendationFetch) {
      const data = await fetchRecentAndRecommendationProducts({
        language,
        type: RECOMMENDATION_TYPE.HomePage,
        usePwgsRecommendationType: enablePwgscheckout,
      })
      setState((state) => ({ ...state, ...data }))
    }
  }, [enablePwgscheckout, language, startRecommendationFetch])

  const setHomePageData = useCallback(async () => {
    const data = await fetchHomePageDefaultData(language, email)
    const {
      banners = [],
      messagesCenter = [],
      messagesData,
      messages = [],
    } = data
    sendBloomReachHomeAnalytics(data.Analytics)
    setState((state) => ({ ...state, banners, messagesData }))
    showApprovalAlerts(messagesCenter)
    showPromotionalAlerts(messagesCenter)
    showApiNotifications(filterAllMessage(messages))
  }, [email, language])

  const handleAddToCart = (cartData) => {
    onAddToCartResponse(cartData, onCartStateFn)
    getProductList()
    getSuspensionMessage()
    getMessagesCenterList()
  }

  const getBudgetCalculatorData = useCallback(
    async (showCustomDates) => {
      const budgetCalculatorData = await cardBudgetData(
        language,
        showCustomDates
      )
      setState((state) => ({ ...state, budgetCalculatorData }))
    },
    [language]
  )

  const getCardOrderData = useCallback(
    async (buyerId, showMenuOrder, showMenuOrderSubmitted) => {
      const orderData = await cardOrderData(
        language,
        buyerId,
        showMenuOrder,
        showMenuOrderSubmitted
      )
      setState((state) => ({ ...state, orderData }))
    },
    [language]
  )

  const getPromoBanners = useCallback(async () => {
    // Prepare data to call new promotions banner
    if (!promotionsData.current[language]) {
      const sofProductPromoId =
        order?.orderProducts?.map((item) => item?.sofproductPromoId) || []
      const sofOrderPromoId = order?.soforderPromoId
      const newPromotionQueryParams = {
        logonName: buyer?.logonName,
        customerType: details?.customerType,
        shipTo: details?.clientNo,
        warehouseNo: details?.warehouseNo,
        segment: details?.marketSegment,
        sicCode: details?.siccode,
        divisionCode: details?.divisionCode,
        appliedPromoList: [...new Set([...sofProductPromoId, sofOrderPromoId])],
      }

      const isEnableNewPromotions = enableNewPromotions && tokenHeader
      const data = isEnableNewPromotions
        ? await fetchNewPromotionBanners(
            language,
            newPromotionQueryParams,
            details.clientId
          )
        : (tokenHeader && (await fetchPromotionBanners(language, 0))) || {}
      isEnableNewPromotions
        ? (promotionsData.current = data)
        : (promotionsData.current[language] = data)
    }

    const { promotions = [] } = promotionsData?.current[language] || {}
    setState((state) => ({ ...state, promoBanner: promotions }))
  }, [
    buyer?.logonName,
    details.clientId,
    details?.clientNo,
    details?.customerType,
    details?.marketSegment,
    details?.siccode,
    details?.warehouseNo,
    language,
    tokenHeader,
    order?.orderProducts,
    order?.soforderPromoId,
    details?.divisionCode,
  ])

  const getMembershipInfo = useCallback(async () => {
    if (!loggedIn) {
      return
    }

    const membershipDetail =
      (await fetchMembershipDetail(
        language,
        buyer.firstName,
        details.principalClientNo,
        settings.showMembershipDashboard,
        details.membershipProgramNo,
        loggedIn
      )) || {}

    if (!checkKeysEmpty(membershipDetail)) {
      setState((state) => ({
        ...state,
        ...{
          userInfo: membershipDetail.userInfo,
          headerRow: membershipDetail.headingLabels,
          bodyRow: membershipDetail.rows || [],
        },
      }))
    }
  }, [
    buyer.firstName,
    details.membershipProgramNo,
    details.principalClientNo,
    language,
    loggedIn,
    settings.showMembershipDashboard,
  ])

  const getCardOrderApprovalData = useCallback(
    async (buyerId, showMenuOrder, showMenuOrderSubmitted) => {
      const orderApprovalData = await cardOrderApprovalData(
        language,
        buyerId,
        showMenuOrder,
        showMenuOrderSubmitted
      )
      setState((state) => ({ ...state, orderApprovalData }))
    },
    [language]
  )

  const getCardListData = useCallback(async () => {
    const listData = await cardListData(language)
    setState((state) => ({ ...state, listData }))
  }, [language])

  const getCustomContentData = useCallback(async () => {
    const sessionClientId = getSessionStorage(CLIENT_ID)
    const finalClientId = clientId || sessionClientId
    const {
      siteContent,
      userId = '',
      showPreloginPageBanner = false,
      status,
    } = await fetchContentData([
      language,
      loggedIn,
      siteContentNo,
      HOME_PAGE_CONTENT,
      finalClientId,
    ])
    const clientIdNotExists =
      [400, 404].includes(status) && finalClientId && !loggedIn
    const goToDefaultHomePage =
      clientIdNotExists || (!userId && !showPreloginPageBanner && !siteContent)
    // When userId and clientId both are available then only store client
    if (!clientIdNotExists && clientId) {
      setSessionStorage(CLIENT_ID, clientId)
    }

    //When client id is wrong then redirect to default home page
    if (goToDefaultHomePage && clientId) {
      removeSessionStorage(CLIENT_ID)
      window.location.assign(getUrl(HOME_ROUTE))
      return
    }

    setState((state) => ({
      ...state,
      cmsContent: typeof siteContent === 'string' ? siteContent : '',
      userId,
      clientId: finalClientId,
      showPreloginPageBanner,
    }))
  }, [clientId, language, loggedIn, siteContentNo])

  const getMessagesCenterList = async () => {
    const { language } = props
    const { messagesCenter = [] } = await fetchHomePageDefaultData(language)

    showApprovalAlerts(messagesCenter)
    showPromotionalAlerts(messagesCenter)
  }

  const getSuspensionMessage = useCallback(() => {
    handleSuspensionMessage({ suspendedDateFrom, suspendedDateUntil }, language)
  }, [suspendedDateFrom, suspendedDateUntil, language])

  const getMemberTiles = useCallback(() => {
    getBudgetCalculatorData(showCustomDates)
    getCardOrderData(buyerId, showMenuOrder, showMenuOrderSubmitted)
    if (isApprover) {
      getCardOrderApprovalData(buyerId, showMenuOrder, showMenuOrderSubmitted)
    }

    getCardListData()
  }, [
    buyerId,
    getBudgetCalculatorData,
    getCardListData,
    getCardOrderApprovalData,
    getCardOrderData,
    isApprover,
    showCustomDates,
    showMenuOrder,
    showMenuOrderSubmitted,
  ])

  const onCartStateFn = (messages) => {
    if (isDialogOpen(PRODUCT_QUICK_VIEW)) {
      showApiNotifications(messages, {
        containerId: QUICK_VIEW_CONTAINER_ID,
        id: QUICK_VIEW_MESSAGE_ID,
      }) // show it in product quick view alerts
    } else {
      showApiNotifications(messages)
    }
  }

  const fetchHomePageData = useCallback(() => {
    getMembershipInfo()
    getProductList()
    if (loggedIn) {
      getMemberTiles()
      getPromoBanners()
    }
    closeAllNotification()
    getSuspensionMessage()
    getCustomContentData()
    setHomePageData()
  }, [
    loggedIn,
    getCustomContentData,
    getMemberTiles,
    getMembershipInfo,
    getProductList,
    getPromoBanners,
    getSuspensionMessage,
    setHomePageData,
  ])

  useEffect(() => {
    fetchHomePageData()
    showDeletedItemsAlerts()
    showShipToChangeAdditionalMessage(language)
  }, [fetchHomePageData, language])

  const {
    recentlyViewedProducts,
    recommendationProducts,
    promoBanner,
    userInfo,
    headerRow,
    bodyRow,
    banners,
    cmsContent,
    userId,
    clientId: stateClientId,
    showPreloginPageBanner,
  } = state

  const { pageTitle } = pageMessages[language]
  const { exists: allowanceExists } = allowance
  const showAllowanceDashboard =
    displayAllowance && (allowanceExists || allowance.isLoading)
  const allowanceData = {
    ...allowance,
    ...(allowanceExists && {
      firstName: buyer.firstName,
    }),
  }
  const shouldShowNowPreLogin = !!(stateClientId && userId && !loggedIn)

  return (
    <Layout
      {...props}
      messages={messages}
      pageName={PAGE_NAMES.HOME}
      hasPromotions={!!promoBanner?.length}
      pageTitle={pageTitle}
    >
      <Home
        language={language}
        settings={settings || {}}
        showAllowanceDashboard={showAllowanceDashboard}
        allowanceData={allowanceData}
        recommendedProducts={recommendationProducts}
        recentViewedProducts={recentlyViewedProducts}
        bannerImages={banners}
        isLoggedIn={loggedIn}
        cmsContent={cmsContent}
        userInfo={userInfo}
        headerRow={headerRow}
        bodyRow={bodyRow}
        addToCartHandler={handleAddToCart}
        budgetCalculatorData={state.budgetCalculatorData}
        orderData={state.orderData}
        orderApprovalData={state.orderApprovalData}
        listData={state.listData}
        messagesData={state.messagesData}
        promotionalProducts={promoBanner}
        isApprover={isApprover}
        pageName={PAGE_NAMES.HOME}
        showShopNowPreLogin={shouldShowNowPreLogin}
        showPreloginPageBanner={showPreloginPageBanner}
        showPreLoginPageDetail={stateClientId ? true : showPreLoginPageDetail}
        clientId={stateClientId}
        logonName={userId}
      />
    </Layout>
  )
}

HomePage.getInitialProps = async ({ query, apiData }) => {
  const order = {
    ...queryParamsToProps(query),
  }

  return {
    ...(order && { order }),
    ...apiData,
  }
}

// PropType validation
HomePage.propTypes = {
  buyer: PropTypes.object,
  settings: PropTypes.object,
  language: PropTypes.string,
  order: PropTypes.object,
  tokenHeader: PropTypes.string,
  details: PropTypes.object,
  adobeAnalytics: PropTypes.object,
}

const defaultState = {
  userInfo: {},
  headerRow: [],
  bodyRow: [],
  banners: [],
  allowance: {},
  recentlyViewedProducts: null,
  recommendationProducts: null,
  promoBanner: null,
  cmsContent: '',
}
export default languageConnector(withRouter(HomePage))
