import PropTypes from 'prop-types'
import Button from 'shared/Button'
import styles from './TableTogglerButton.module.scss'
import messages from './messages'

/**
 * Name: TableTogglerButton
 * Desc: render toggle button, used for toggle body
 * @param {string} language
 * @param {boolean} isOpen
 * @param {func} toggleHandler
 */
const TableTogglerButton = ({ language, isOpen, toggleHandler }) => (
  <div className={styles.toggleButton}>
    <Button
      aria-label={
        isOpen
          ? messages[language].caretOpenLabel
          : messages[language].caretDownLabel
      }
      variant="link"
      data-togglestate={isOpen ? 0 : 1}
      onClick={toggleHandler}
      text=""
      icon="carrot"
    />
  </div>
)

TableTogglerButton.propTypes = {
  language: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  toggleHandler: PropTypes.func.isRequired,
}

export default TableTogglerButton
