import { useState } from 'react'
import { getSearchOrder } from 'services/orderService'
import messages from '../../messages'
import EwayLink from '~/components/shared/EwayLink'
import { getOrderDetailsUrl } from 'components/containers/OrderList/OrderListApiUtils'
import {
  ORDER,
  APPROVALS,
} from '~/components/containers/Home/MembershipCardPanel/MembershipVariants/MembershipVariantsConstants'

export const useOrderSearch = (language, buyerId, orderData) => {
  const { searchCountMsg, searchMultipleCountMsg, noDataFoundMsg } =
    messages[language]

  const [ordersSearchResult, setOrdersSearchResult] = useState([])
  const [searchKey, setSearchKey] = useState('')

  const handleOnSearch = async (event) => {
    const { target } = event
    const searchBy = target.value.trim()

    if (searchBy) {
      const { orderData } = await getSearchOrder(language, buyerId, searchBy)
      setOrdersSearchResult(orderData)
    }
    setSearchKey(searchBy)
  }

  const isActiveSearch = !!searchKey
  const searchResultCount = ordersSearchResult.length
  const searchCountMessage =
    searchResultCount === 1 ? searchCountMsg : searchMultipleCountMsg
  const searchResultMsg =
    searchResultCount > 0
      ? `${searchResultCount} ${searchCountMessage}`
      : noDataFoundMsg
  const orders = isActiveSearch ? ordersSearchResult : orderData

  return {
    orders,
    handleOnSearch,
    isActiveSearch,
    searchKey,
    searchResultMsg,
    orderTableRows: getOrderTableRows(language, orders),
  }
}

function getOrderTableRows(language, orders) {
  const rows = orders.map((order) => {
    const [date, orderNo, orderId] = order
    const orderDetailsHref = getOrderDetailsUrl(orderId, language)
    return [
      <EwayLink
        data-test="order-number"
        text={orderNo}
        href={orderDetailsHref}
        key={orderNo}
      />,
      date,
    ]
  })
  return rows
}

export const getCardType = (cardType) => {
  const isOrderCardTile = cardType === ORDER
  const isApprovalsCardTile = cardType === APPROVALS

  return { isOrderCardTile, isApprovalsCardTile }
}
