import PropTypes from 'prop-types'
import { ENGLISH } from '~/redux/constants'
import DashBoardItemTitle from '../DashBoardItemTitle'
import messages from './messages'

function Greeting({ greeting, name = '', language = ENGLISH, ...rest }) {
  const greetMessage = greeting || messages[language].greeting

  return (
    <DashBoardItemTitle
      color="primary"
      text={`${greetMessage}, ${name}`}
      {...rest}
    />
  )
}

Greeting.propTypes = {
  greeting: PropTypes.string,
  language: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
}

export default Greeting
