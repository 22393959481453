import PropTypes from 'prop-types'
import MembershipBudgetCalculator from '~/components/containers/Home/MembershipCardPanel/MembershipVariants/MembershipBudgetCalculator'
import MembershipOrder from '~/components/containers/Home/MembershipCardPanel/MembershipVariants/MembershipOrder'
import MembershipCardMessages from '~/components/containers/Home/MembershipCardPanel/MembershipVariants/MembershipCardMessages'
import MembershipShoppingList from '~/components/containers/Home/MembershipCardPanel/MembershipVariants/MembershipShoppingList'
import MembershipCardPanelSkeleton from '../MembershipCardPanelSkeleton'
import {
  APPROVALS,
  BUDGET_CALCULATOR,
  NOTIFICATION,
  ORDER,
  SHOPPING_LIST,
} from './MembershipVariantsConstants'

/**
 *Name: CardVariants.js
 *Desc: Render CardVariants content
 * @param {*} cardData
 * @param {*} cardType
 * @returns Element
 */

function MembershipVariants({ cardType, cardData, language }) {
  const dataLength = Object.keys(cardData).length
  const enableSkeleton = !dataLength

  switch (cardType) {
    case BUDGET_CALCULATOR:
      return enableSkeleton ? (
        <MembershipCardPanelSkeleton title={cardType} />
      ) : (
        <MembershipBudgetCalculator {...cardData} />
      )
    case ORDER:
    case APPROVALS:
      return enableSkeleton ? (
        <MembershipCardPanelSkeleton title={cardType} />
      ) : (
        <MembershipOrder {...cardData} cardType={cardType} />
      )
    case SHOPPING_LIST:
      return enableSkeleton ? (
        <MembershipCardPanelSkeleton title={cardType} />
      ) : (
        <MembershipShoppingList {...cardData} language={language} />
      )
    case NOTIFICATION:
      return dataLength <= 1 ? (
        <MembershipCardPanelSkeleton title={cardType} />
      ) : (
        <MembershipCardMessages {...cardData} />
      )
  }

  return null
}

// Props Validation
MembershipVariants.propTypes = {
  cardType: PropTypes.string.isRequired,
  cardData: PropTypes.object.isRequired,
  language: PropTypes.string,
}

export default MembershipVariants
