import { ENGLISH } from '@redux/constants'
import { getOldApiUrl } from 'services/utils/configUtils'
import { checkArgsEmpty, sendRequest } from './apiHelper'

const OLD_API_URL = getOldApiUrl()

/**
 * Name: FetchMembershipDetail
 * Desc: get Membership detail by ClientNo
 * @param {*} language
 * @param {*} clientId
 */
const buildFetchMembershipDetail = async (args) => {
  const [language = ENGLISH, clientNo] = args

  const url = `${OLD_API_URL}/Api/membership_program/${clientNo}`
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': `${language}-CA`,
    },
  }
  return sendRequest(url, options)
}

export const getMembershipDetail = checkArgsEmpty(buildFetchMembershipDetail)
