/**
 * Name: customContentService
 * Desc: business logic for custom content data
 */
import { getCustomContentData } from '~/api/customContentOperations'

const BEFORE_LOGIN_KEY = 'BEFORE_LOGIN_KEY'
const AFTER_LOGIN_KEY = 'AFTER_LOGIN_KEY'
export const HOME_PAGE_CONTENT = 'HOME_PAGE_CONTENT'
export const SUPPORT_CONTENT = 'SUPPORT_CONTENT'

const CONTENTS = {
  [HOME_PAGE_CONTENT]: {
    [BEFORE_LOGIN_KEY]: 'homePageCustomContentSection2',
    [AFTER_LOGIN_KEY]: 'postLoginHomePageCustomContent',
  },

  [SUPPORT_CONTENT]: {
    [BEFORE_LOGIN_KEY]: 'mySupportCustomContent',
    [AFTER_LOGIN_KEY]: 'mySupportCustomContent',
  },
}

/**
 * Name: fetchContentData
 * @param {String} language
 * @param {Boolean} isLoggedIn
 * @param {Number} customContentId
 */

export async function fetchContentData(args) {
  const [language, isLoggedIn, customContentId, location, clientId = ''] = args
  const data = await getCustomContentData([customContentId, language, clientId])
  const siteContent = filterCustomContent(data, isLoggedIn, location)
  return { siteContent, ...data }
}

/**
 * Name: filterSiteContentData
 * Desc: filter the api data and customize array according to pre / post login
 * @param {Array} data
 * @param {String} language
 * @param {Boolean} isLoggedIn
 */
const filterCustomContent = (data, isLoggedIn, location) => {
  return getCustomContent(isLoggedIn, data, location)
}

const getCustomContent = (isLoggedIn, customContent, location) => {
  if (!isLoggedIn) {
    return customContent[CONTENTS[location][BEFORE_LOGIN_KEY]]
  }

  if (isLoggedIn) {
    return customContent[CONTENTS[location][AFTER_LOGIN_KEY]]
  }
  return null
}
