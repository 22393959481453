import PropTypes from 'prop-types'
import Text from 'components/shared/Text'
import Icon from 'components/shared/Icon'
import styles from './InfoMessage.module.scss'

/**
 *Name: InfoMessage.js
 *Desc: Render InfoMessage text

* @param {*} text
* @param {*} variant
* @param {*} color
* @param {*} icon
* @returns Element
 */

const InfoMessage = ({ text, variant, color, icon, dataTest }) => {
  return (
    <div className={styles.successMessage}>
      <div className={styles.wrapperIcon}>
        <Icon variant={icon} />
      </div>
      <Text text={text} variant={variant} color={color} data-test={dataTest} />
    </div>
  )
}

// Props Validation
InfoMessage.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  dataTest: PropTypes.string,
}
export default InfoMessage
