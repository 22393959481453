import { getLanguageSuffix } from 'services/utils'
import { getApiUrl } from 'services/utils/configUtils'
import { checkArgsEmpty, sendRequest } from './apiHelper'

const API_URL = getApiUrl()
const DEFAULT_VALUE = 1

/**
 * Name: buildFetchCustomContent
 * Desc: gets custom site content from API
 * @param {Number} customContentId
 * @param {String} language
 * @param {String} clientId
 */
const buildFetchCustomContent = async (args) => {
  const [customContentId = DEFAULT_VALUE, language, clientId = ''] = args
  const languageSuffix = getLanguageSuffix(language)
  const cultureQuery = `cultureCode=${languageSuffix}`
  const contentUrl = clientId
    ? `clients/${clientId}/siteContentDetails`
    : `site-content/${customContentId}/details`

  const url = `${API_URL}/${contentUrl}?${cultureQuery}`
  const response = await sendRequest(url)
  return response
}

export const getCustomContentData = checkArgsEmpty(buildFetchCustomContent)
