import PropTypes from 'prop-types'
import Heading from 'shared/Heading'
import EwayLink from '~/components/shared/EwayLink'
import { ENGLISH } from '~/redux/constants'
import MembershipShoppingListItem from './MembershipShoppingListItem'
import messages from 'components/containers/ShoppingCart/ShoppingCartUtilityPanel/messages'
import Flex from '~/components/shared/Flex'

/**
 *Name: ShoppingList.js
 *Desc: Render ShoppingList card content

 * @param {String} viewAllUrl
 * @param {Object} listData
 * @param {Array} items
 * @param {Boolean} showViewAll
 * To see data layer look in "services/shoppingListService.js"
 * @returns Element
 */

const MembershipShoppingList = ({
  viewAllUrl,
  items = [],
  showViewAll,
  language = ENGLISH,
}) => {
  const { viewAllLabel, listsTileHeadingLabel: heading } = messages[language]
  const showList = !!items.length
  return (
    <div>
      <Flex justify="space-between" align="center" pb="18px">
        {heading ? <Heading as="h2" text={heading} /> : null}
        {showViewAll ? (
          <EwayLink
            dataTest="view-all-shopping-list"
            href={viewAllUrl}
            fontFamily="md"
            cursor="pointer"
            textDecoration={true}
            text={viewAllLabel}
          />
        ) : null}
      </Flex>
      {showList ? (
        <Flex as="ul" w="100%" justify="space-around">
          {items.map((item, index) => {
            const key = item.id || index
            return (
              <MembershipShoppingListItem
                key={key}
                showPlusSign={!item.id}
                {...item}
              />
            )
          })}
        </Flex>
      ) : null}
    </div>
  )
}

// Props Validation
MembershipShoppingList.propTypes = {
  heading: PropTypes.string,
  viewAllUrl: PropTypes.string,
  showViewAll: PropTypes.bool,
  items: PropTypes.array,
  language: PropTypes.string,
}
export default MembershipShoppingList
