import CarouselArrow from 'shared/Carousel/CarouselArrow'
export const carouselSettings = {
  autoplaySpeed: 6000,
  className: 'slickMain',
  adaptiveHeight: true,
  useTransform: true,
  slide: 'div',
  cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
}

export function getCarouselSettings(settings, language) {
  return {
    ...settings,
    nextArrow: <CarouselArrow as="button" type="button" language={language} />,
    prevArrow: <CarouselArrow as="button" type="button" language={language} />,
  }
}
