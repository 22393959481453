export default {
  en: {
    hiLabel: 'Hi',
    premiumLabel: 'Premium',
    renewalDateLabel: 'Renewal Date',
    annualCompanySavingsLabel: 'Annual Company Savings',
    findEvenMoreLabel:
      'Find even more great products and savings online by viewing our',
    flyersAndPublicationsLabel: 'flyers and publications.',
    flyersAndPublicationsUrlLabel: 'en/Landing/Publications_EN',
    annualCompSavBreakdownLabel: 'Annual company savings breakdown',
    annualPremiumSavingsLabel: 'Annual premium savings:',
    annualContSavingLabel: 'Annual contract savings:',
    annualDescLabel:
      'Savings calculator notes dollars saved annually (Rounded to the nearest dollar) based on the renewal date. Savings are based on your membership and contract pricing. The calculator is not updated live, savings on purchases will show on the calculator next business day.',
  },
  fr: {
    hiLabel: 'Bonjour',
    premiumLabel: 'Premium',
    renewalDateLabel: 'Date de renouvellement',
    annualCompanySavingsLabel: 'Épargne annuelle de la compagnie',
    findEvenMoreLabel:
      'Trouvez encore plus de produits et de spéciaux exceptionnels en ligne en consultant nos',
    flyersAndPublicationsLabel: 'circulaires et publications.',
    flyersAndPublicationsUrlLabel: 'fr/Landing/Publications_EN',
    annualCompSavBreakdownLabel:
      'Répartition annuelle des épargnes de la compagnie',
    annualPremiumSavingsLabel: 'Épargne annuelle premium :',
    annualContSavingLabel: 'Épargne annuelle contrat :',
    annualDescLabel:
      "Le calculateur d'épargne indique le montant sauvé annuellement (arrondi au dollar le plus près) selon la date de renouvellement. L'épargne est basée sur le prix de votre abonnement et vos prix de contrat. Le calculateur n'est pas mis à jour en temps réel; l'économie sur les achats s'affiche dans le calculateur le jour ouvrable suivant.",
  },
}
