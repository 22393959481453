export default {
  en: {
    addToShoppingListLabel: 'Add to shopping list',
    alternateCodeLabel: 'Alternate code:',
    shopAsGuest: 'Shop as guest',
    unitOfMeasureLabel: 'Unit of Measure:',
    thisItemNotAvailableLabel: 'This item is not available in this quantity',
    itemHasBeenSuccessfullyLabel:
      'Item has been successfully added to the shopping list',
    beforeLoginPricingText: 'Login for pricing and availability information.',
  },
  fr: {
    addToShoppingListLabel: "Ajouter à la liste d'achat",
    alternateCodeLabel: 'Code alternatif:',
    shopAsGuest: 'Magasinez en tant que visiteur',
    unitOfMeasureLabel: 'Unité de mesure:',
    thisItemNotAvailableLabel: 'This item is not available in this quantity',
    itemHasBeenSuccessfullyLabel:
      "L'article a été ajouté avec succès à la liste d'achats",
    beforeLoginPricingText:
      'Connectez-vous pour connaître les prix et la disponibilité.',
  },
}
