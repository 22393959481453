import SiteContent from 'components/shared/SiteContent'
import PropTypes from 'prop-types'
import { ADD_TO_CART_HOME_PAGE } from 'shared/AdobeAnalytics/AdobeAnalyticsConstants'
import Banner from 'shared/Banner'
import Container from 'shared/Container'
import { AnalyticsContext } from 'shared/Utils/contexts'
import ProductSection from '~/components/shared/ProductSection'
import { checkKeysEmpty } from '~/services/utils'
import AllowanceDashboard from './AllowanceDashboard/AllowanceDashboard'
import MembershipCardPanel from './MembershipCardPanel'
import MembershipDashboard from './MembershipDashboard'
import messages from './messages'
import PromotionalProducts from './PromotionalProducts'
import {
  RECENTLY_VIEW_SEGMENT_LOCATION,
  HOME_PAGE_RECOMMENDATION_SEGMENT_LOCATION,
} from 'components/shared/SegmentAnalytics/SegmentAnalyticsConstants'
import ShopNowPreLogin from './ShopNowPreLogin'
/**
 * Name: Home
 * Desc: Combine all component related to Home page
 * @param {*} hostUrl
 * @param {object} budgetCalculatorData
 * @param {object} orderData
 * @param {object} orderApprovalData
 * @param {object} listData
 * @param {object} messagesData
 * @param {bool} isApprover
 * @param {string} pageName
 * @returns Element
 */

const Home = ({
  isLoggedIn,
  language,
  bannerImages,
  recommendedProducts,
  recentViewedProducts,
  settings,
  cmsContent,
  userInfo,
  headerRow,
  bodyRow,
  promotionalProducts,
  addToCartHandler,
  budgetCalculatorData,
  orderData,
  orderApprovalData,
  listData,
  messagesData,
  isApprover,
  pageName,
  showAllowanceDashboard,
  allowanceData,
  showShopNowPreLogin = false,
  showPreloginPageBanner = false,
  clientId = '',
  logonName = '',
  showPreLoginPageDetail = false,
}) => {
  const {
    enableRecommendationProduct,
    enableRecentlyViewedFooter,
    showYourPriceFlag,
    showContractCareOf,
    showContractIcon,
    showHomePageBannerControl,
    productReviewsCanRead,
    enableInstrumentation,
  } = settings
  const showRecommendations =
    recommendedProducts === null || !!recommendedProducts?.length
  const showRecentlyViewed =
    recentViewedProducts === null || !!recentViewedProducts?.length
  const showRecommendationsWrapper = enableRecommendationProduct && isLoggedIn
  const showCustomContent = isLoggedIn && cmsContent

  const showBannerImages = showPreLoginPageDetail
    ? showPreloginPageBanner
    : (isLoggedIn && showHomePageBannerControl) || !isLoggedIn

  const showMembershipDashboard = isLoggedIn && !checkKeysEmpty(userInfo)
  const showPromotionalProducts =
    isLoggedIn &&
    (promotionalProducts === null || !!promotionalProducts?.length)
  const showMemberShipCardPanel = isLoggedIn
  const showCmsContent = !isLoggedIn && cmsContent
  const showRecentViewFooter = enableRecentlyViewedFooter && isLoggedIn

  return (
    <>
      {showBannerImages ? (
        <Banner bannerImages={bannerImages} language={language} />
      ) : null}

      {showMembershipDashboard ? (
        <MembershipDashboard
          language={language}
          userInfo={userInfo}
          headerRow={headerRow}
          bodyRow={bodyRow}
        />
      ) : null}
      {showAllowanceDashboard ? (
        <Container as="section" mb="20px">
          <AllowanceDashboard
            language={language}
            firstName={allowanceData.firstName}
            total={allowanceData.total}
            remainingAmount={allowanceData.remainingAmount}
            showSkeleton={allowanceData.isLoading}
          />
        </Container>
      ) : null}

      {showMemberShipCardPanel ? (
        <MembershipCardPanel
          settings={settings}
          language={language}
          budgetCalculatorData={budgetCalculatorData}
          orderData={orderData}
          orderApprovalData={orderApprovalData}
          listData={listData}
          messagesData={messagesData}
          isApprover={isApprover}
        />
      ) : null}
      {showPromotionalProducts ? (
        <PromotionalProducts
          products={promotionalProducts || []}
          language={language}
          showSkeleton={promotionalProducts === null}
        />
      ) : null}

      {showCustomContent ? <SiteContent cmsContent={cmsContent} /> : null}

      {showRecommendationsWrapper ? (
        <AnalyticsContext.Provider
          value={{
            quickViewAddToCartAnalyticsData:
              ADD_TO_CART_HOME_PAGE.QUICK_VIEW_RECOMMENDATION,
            segmentLocation: HOME_PAGE_RECOMMENDATION_SEGMENT_LOCATION,
          }}
        >
          {showRecommendations ? (
            <ProductSection
              title={messages[language].itemsYouMightLikeLabel}
              language={language}
              isLoggedIn={isLoggedIn}
              productList={recommendedProducts || []}
              showSkeleton={recommendedProducts === null}
              showYourPriceFlag={showYourPriceFlag}
              showContractCareOf={showContractCareOf}
              showContractIcon={showContractIcon}
              addToCartHandler={addToCartHandler}
              showProductReviews={productReviewsCanRead}
              callingLocation={ADD_TO_CART_HOME_PAGE.RECOMMENDATION}
              pageName={pageName}
              dataTest="recommendations"
              enableInstrumentation={enableInstrumentation}
            />
          ) : null}
        </AnalyticsContext.Provider>
      ) : null}

      {showRecentViewFooter ? (
        <AnalyticsContext.Provider
          value={{
            quickViewAddToCartAnalyticsData:
              ADD_TO_CART_HOME_PAGE.QUICK_VIEW_RECENT_VIEWED,
            segmentLocation: RECENTLY_VIEW_SEGMENT_LOCATION,
          }}
        >
          {showRecentlyViewed ? (
            <ProductSection
              title={messages[language].recentlyViewedItemsLabel}
              language={language}
              isLoggedIn={isLoggedIn}
              productList={recentViewedProducts || []}
              showSkeleton={recentViewedProducts === null}
              showYourPriceFlag={showYourPriceFlag}
              showContractCareOf={showContractCareOf}
              showContractIcon={showContractIcon}
              addToCartHandler={addToCartHandler}
              showProductReviews={productReviewsCanRead}
              callingLocation={ADD_TO_CART_HOME_PAGE.RECENT_VIEWED}
              pageName={pageName}
            />
          ) : null}
        </AnalyticsContext.Provider>
      ) : null}
      {showCmsContent ? <SiteContent cmsContent={cmsContent} /> : null}
      {showShopNowPreLogin ? (
        <ShopNowPreLogin
          language={language}
          clientId={clientId}
          logonName={logonName}
        />
      ) : null}
    </>
  )
}

// Props Validation
Home.propTypes = {
  language: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    enableRecentlyViewedFooter: PropTypes.bool,
    showHomePageBannerControl: PropTypes.any,
    productReviewsCanRead: PropTypes.any,
    loggedIn: PropTypes.bool,
    enableRecommendationProduct: PropTypes.bool,
    showYourPriceFlag: PropTypes.bool,
    showContractCareOf: PropTypes.bool,
    showContractIcon: PropTypes.bool,
    enableInstrumentation: PropTypes.bool,
  }),
  isLoggedIn: PropTypes.bool,
  addToCartHandler: PropTypes.func,
  budgetCalculatorData: PropTypes.object,
  orderData: PropTypes.object,
  orderApprovalData: PropTypes.object,
  listData: PropTypes.object,
  messagesData: PropTypes.object,
  cmsContent: PropTypes.string,
  userInfo: PropTypes.object,
  allowanceData: PropTypes.object,
  headerRow: PropTypes.array,
  bodyRow: PropTypes.array,
  isApprover: PropTypes.bool,
  showAllowanceDashboard: PropTypes.bool,
  pageName: PropTypes.string,
  bannerImages: PropTypes.array,
  recommendedProducts: PropTypes.array,
  recentViewedProducts: PropTypes.array,
  promotionalProducts: PropTypes.array,
  showShopNowPreLogin: PropTypes.bool,
  showPreloginPageBanner: PropTypes.bool,
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  logonName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showPreLoginPageDetail: PropTypes.bool,
}

export default Home
