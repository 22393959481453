import messages from 'components/containers/Home/MembershipCardPanel/messages'
import DynamicTranslation from 'components/shared/DynamicTranslation/DynamicTranslation'
import { getHostUrl } from 'services/utils/configUtils'
import {
  bloomReachAnalyticsFirstCallStatus,
  sendBloomReachPageTracking,
} from 'shared/BloomReachAnalytics/BloomReachAnalyticsUtils'
import { withUniqueId } from '~/api/apiHelper'
import { getHomePageDefaultData } from '~/components/containers/Home/HomeApiUtils'
import EwayLink from '~/components/shared/EwayLink'
import { ENGLISH } from '~/redux/constants'
const HOST_URL = getHostUrl()

/**
 * Name: fetchHomePageDefaultData
 * @param {*} language
 * @param {*} emailAccountRep
 */
export async function fetchHomePageDefaultData(
  language = ENGLISH,
  emailAccountRep = ''
) {
  const data = await getHomePageDefaultData(language)
  const hasData = data.MessagesCenter && data.Messages
  const formattedData = {
    messagesCenter: hasData
      ? [...new Set([...data.MessagesCenter, ...data.Messages])]
      : data?.MessagesCenter || [],

    messagesData: formatMessagesTileData(
      language,
      data.MessagesCenter,
      emailAccountRep
    ),
    banners: data.Banners?.Banners,
    messages: data.Messages,
  }
  return formattedData
}

export function sendBloomReachHomeAnalytics(Analytics = {}) {
  const analyticsProperties = Analytics.Bloomreach?.AnalyticsProperties || {}
  if (bloomReachAnalyticsFirstCallStatus()) {
    return
  }

  bloomReachAnalyticsFirstCallStatus(true)
  sendBloomReachPageTracking(analyticsProperties)
}

/**
 * Name: filterMessageTileData
 * Desc: Filter data for Messages tile
 * @param {object} apiMessageData
 * @param {*} language
 * @param {*} emailAccountRep
 */

/**
 * Name: filterMessageTileData
 * Desc: Filter data for Messages tile
 * @param {object} apiMessageData
 * @param {*} language
 * @param {*} emailAccountRep
 */

const formatMessagesTileData = (
  language,
  apiMessageData = [],
  emailAccountRep
) => {
  const messagesObj = {}
  if (apiMessageData.length && Array.isArray(apiMessageData)) {
    const results = apiMessageData.filter((item) => item.MessagePosition === 1)
    messagesObj.list = results.map((result) => {
      const data = {}
      data['messageText'] = result.PrimaryMessage
      return data
    })
  } else {
    messagesObj.list = []
  }
  if (emailAccountRep) {
    const linkAccountLink = (
      <DynamicTranslation
        text={messages[language].helpMessage}
        replace="{link}"
        with={
          <EwayLink
            text={messages[language].clickHereText}
            href={`${HOST_URL}/${language}/Info/Help/ContactUs`}
          />
        }
      />
    )
    messagesObj.list.unshift({
      messageText: linkAccountLink,
    })
  }
  messagesObj.list = withUniqueId(messagesObj.list)
  messagesObj.heading = messages[language].messagesTileHeadingLabel
  messagesObj.noMsgLabel = messages[language].noMsgLabel
  return messagesObj
}
