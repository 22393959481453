import messages from 'components/containers/Home/MembershipDashboard/messages'
import { getMembershipDetail } from '~/api/membershipOperations'
import { priceFormat, customDateFormat, checkKeysEmpty } from './utils'

/**
 * Name: fetchMembershipDetail
 * Desc: business logics for Membership functionality
 * @param {string} language
 * @param {number} clientNo
 * @param {string} firstName
 * @param {boolean} showMembershipDashboard
 * @param {number} membershipProgram
 * @param {boolean} loggedIn
 */

export async function fetchMembershipDetail(
  language,
  firstName,
  clientNo,
  showMembershipDashboard,
  membershipProgramNo,
  loggedIn
) {
  if (
    clientNo &&
    showMembershipDashboard &&
    membershipProgramNo > 0 &&
    loggedIn
  ) {
    const membershipInfo = (await getMembershipDetail(language, clientNo)) || {}

    if (checkKeysEmpty(membershipInfo)) {
      return {}
    }

    const userInfo = {
      name: firstName,
      programName: membershipInfo.ProgramName,
      inscriptionDate: membershipInfo.InscriptionDate,
      renewalDate: customDateFormat(membershipInfo.RenewalDate),
      annualSavings: membershipInfo.AnnualSavings,
      annualTotalSavings: membershipInfo.AnnualTotalSavings,
      annualMembershipSavings: membershipInfo.AnnualMembershipSavings,
      annualContractSavings: membershipInfo.AnnualContractSavings,
      productHandlingCost: membershipInfo.productHandlingCost,
      benefit: membershipInfo.Benefit,
    }

    const membershipTableInfo = filterDataForMembershipTable(
      language,
      membershipInfo.LineOfBusinessMembershipSavings,
      membershipInfo.LineOfBusinessContractSavings,
      membershipInfo.LineOfBusinessTotalSavings
    )

    return { ...membershipTableInfo, userInfo }
  }
  return {}
}

const filterDataForMembershipTable = (
  language,
  lineOfBusinessMembershipSavings,
  lineOfBusinessContractSavings,
  lineOfBusinessTotalSavings
) => {
  /**
   * Heading combines one or more data with different Json attributes responses
   */
  let headingLabels = []
  const rows = []

  // For heading Array
  if (!checkKeysEmpty(lineOfBusinessTotalSavings)) {
    headingLabels = [
      messages[language].categorySavingsLabel,
      `${messages[language].businessEssentialsLabel} ${priceFormat(
        lineOfBusinessTotalSavings['Business Essentials'],
        language
      )}`,
      `${messages[language].facilitiesLabel} ${priceFormat(
        lineOfBusinessTotalSavings['Facilities'],
        language
      )}`,
      `${messages[language].furnitureLabel} ${priceFormat(
        lineOfBusinessTotalSavings['Furniture'],
        language
      )}`,
      `${messages[language].printPromoLabel} ${priceFormat(
        lineOfBusinessTotalSavings['Print/Promo'],
        language
      )}`,
      `${messages[language].techLabel} ${priceFormat(
        lineOfBusinessTotalSavings['Tech'],
        language
      )}`,
    ]
  }

  // For Premium Row
  if (!checkKeysEmpty(lineOfBusinessMembershipSavings)) {
    rows.push([
      messages[language].premiumLabel,
      priceFormat(
        lineOfBusinessMembershipSavings['Business Essentials'],
        language
      ),
      priceFormat(lineOfBusinessMembershipSavings['Facilities'], language),
      priceFormat(lineOfBusinessMembershipSavings['Furniture'], language),
      priceFormat(lineOfBusinessMembershipSavings['Print/Promo'], language),
      priceFormat(lineOfBusinessMembershipSavings['Tech'], language),
    ])
  }

  // For contract Row
  if (!checkKeysEmpty(lineOfBusinessContractSavings)) {
    rows.push([
      messages[language].contractLabel,
      priceFormat(
        lineOfBusinessContractSavings['Business Essentials'],
        language
      ),
      priceFormat(lineOfBusinessContractSavings['Facilities'], language),
      priceFormat(lineOfBusinessContractSavings['Furniture'], language),
      priceFormat(lineOfBusinessContractSavings['Print/Promo'], language),
      priceFormat(lineOfBusinessContractSavings['Tech'], language),
    ])
  }

  return { headingLabels, rows }
}
