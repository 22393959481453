import PropTypes from 'prop-types'
import Container from '~/components/shared/Container'
import Box from 'shared/Box'
import Flex from 'shared/Flex'
import Heading from 'shared/Heading'
import Text from 'shared/Text'
import { useSignInForms } from '~/components/shared/Layout/Header/HeaderMiddleSection/SupportMenu/SignIn/SignInUtils'
import UserLogin, {
  UserLoginPasswordField,
  UserLoginSaveUserIdField,
  UserLoginSignInButton,
  UserLoginUserIdField,
} from '~/components/shared/Layout/Header/HeaderMiddleSection/SupportMenu/SignIn/UserLogin'
import UserIDReminder from '~/components/shared/Layout/Header/HeaderMiddleSection/SupportMenu/SignIn/UserIDReminder'
import PasswordReminder from '~/components/shared/Layout/Header/HeaderMiddleSection/SupportMenu/SignIn/PasswordReminder'
import Button from 'shared/Button'
import EwayLink from 'shared/EwayLink'
import messages from './messages'
import styles from './ShopNowPreLogin.module.scss'
import { SHOW_NOW } from './ShopNowPreLoginConstant'
import { ENGLISH } from '~/redux/constants'
import classNames from 'classnames'
import { SHOW_USER_LOGIN } from '~/components/shared/Layout/Header/HeaderMiddleSection/SupportMenu/SignIn/signInConstants'
import { ssoLogin } from './ShopNowPreLoginApiUtils'
import { HOME_ROUTE } from '~/components/shared/EwayRouter/EwayRouterConstants'
import { getUrl } from '~/components/shared/EwayRouter/EwayRouter'
import { showNotification } from '~/components/shared/Alerts'
import { removeSessionStorage } from '~/services/storageManager'
import { CLIENT_ID } from '../constants'

const ShopNowPreLogin = ({
  language = ENGLISH,
  settings = {},
  buyer = {},
  pageName = '',
  clientId = '',
  logonName = '',
}) => {
  const {
    createProfileHeading,
    createProfileSubHeading,
    singInHeading,
    singInSubHeading,
    shopNow,
    errorShopNowLogin,
  } = messages[language]
  const {
    setCurrentFormState,
    showUserIdReminderForm,
    showPasswordReminderForm,
    showUserLoginForm,
    showLoginLink,
  } = useSignInForms(language)
  const handleSetLoginView = (e = {}) => {
    const { target: { dataset: { id = SHOW_USER_LOGIN } = {} } = {} } = e
    setCurrentFormState(Number(id))
  }
  const showShopNowButton = clientId && logonName

  async function handleClickShowNowButton() {
    const { isSuccess, redirectUrl } = await ssoLogin({ clientId, logonName })
    if (isSuccess) {
      removeSessionStorage(CLIENT_ID)
      window.location.href = `${getUrl(HOME_ROUTE)}${redirectUrl}`
    } else {
      showNotification.error(errorShopNowLogin)
    }
  }

  const loginSubHeading = classNames(styles.loginSubHeading, styles.subHeading)

  return (
    <Container>
      <Flex
        flexWrap="wrap"
        minH="400px"
        className={styles.shopNowPreLoginMainWrapper}
      >
        <Box
          w="100%"
          pb="30px"
          className={styles.shopNowPreLoginWrapper}
          data-test="createProfileShopNow"
        >
          <Heading text={createProfileHeading} as="h2" />
          <Text
            text={createProfileSubHeading}
            className={styles.subHeading}
            as="p"
            variant="lgText"
          />
          {showShopNowButton ? (
            <Button
              text={shopNow}
              data-test="show-now-pre-login-button"
              isFullWidth={false}
              onClick={handleClickShowNowButton}
              variant="primary"
              disabled={!logonName}
            />
          ) : null}
        </Box>

        <Box
          w="100%"
          pt="30px"
          className={styles.loginWrapper}
          data-test="loginShopNow"
        >
          <Heading text={singInHeading} as="h2" textAlign="center" />
          <Text
            text={singInSubHeading}
            className={loginSubHeading}
            as="p"
            variant="lgText"
          />
          {showUserIdReminderForm ? (
            <UserIDReminder
              language={language}
              isShowBorderBottom={false}
              isFullSizeButton={false}
            />
          ) : null}
          {showPasswordReminderForm ? (
            <PasswordReminder
              language={language}
              isShowBorderBottom={false}
              isFullSizeButton={false}
            />
          ) : null}
          {showLoginLink ? (
            <Box ml="15px">
              <EwayLink
                id="shop-now-login-link"
                variant="link"
                text={messages[language].signInMsg}
                data-test="show-user-login"
                onClick={handleSetLoginView}
                cursor="pointer"
                textDecoration={true}
                data-id={SHOW_USER_LOGIN}
              />
            </Box>
          ) : null}

          {showUserLoginForm ? (
            <UserLogin
              language={language}
              settings={settings}
              buyer={buyer}
              pageName={pageName}
              setCurrentState={setCurrentFormState}
              isShowBorderBottom={false}
              isFullSizeButton={false}
              location={SHOW_NOW}
            >
              <UserLoginUserIdField
                id="custom-pre-login-userId"
                dataTest="custom-pre-login-userId"
              />
              <UserLoginPasswordField
                id="custom-pre-login-password"
                dataTest="custom-pre-login-password"
              />
              <UserLoginSaveUserIdField
                id="custom-pre-login-rememberMe"
                dataTest="custom-pre-login-rememberMe"
              />
              <UserLoginSignInButton dataTest="custom-pre-login-signInButton" />
            </UserLogin>
          ) : null}
        </Box>
      </Flex>
    </Container>
  )
}

// PropType validation
ShopNowPreLogin.propTypes = {
  language: PropTypes.string.isRequired,
  settings: PropTypes.object,
  buyer: PropTypes.object,
  pageName: PropTypes.string,
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  logonName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default ShopNowPreLogin
