import ContentLoader from 'react-content-loader'

const AllowanceDashboardSkeleton = () => {
  return (
    <ContentLoader
      style={{ width: '100%', minHeight: '181px' }}
      uniqueKey="allowanceSkeleton"
    >
      <rect x="5" y="0" rx="0" ry="0" width="100%" height="100%" />
    </ContentLoader>
  )
}

export default AllowanceDashboardSkeleton
