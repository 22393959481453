import PropTypes from 'prop-types'
import Table from 'shared/Table'
import { useState } from 'react'
import TableTogglerButton from 'shared/Table/TableTogglerButton'
import Container from '~/components/shared/Container'
import styles from './MembershipDashboard.module.scss'
import MembershipDetail from './MembershipDetail'

/**
 * Name: Membership Dashboard
 * Desc: Contain information related to user membership dashboard which combines different component related to membership
 * @param {string} language
 * @param {object} userInfo
 * @param {array} headerRow
 * @param {array} bodyRow
 */

const MembershipDashboard = ({ language, userInfo, headerRow, bodyRow }) => {
  const [toggleTableBody, setToggleTableBody] = useState(true)
  const toggleTableHandler = () => {
    setToggleTableBody((lastToggleState) => !lastToggleState)
  }

  const columns = headerRow.map((headerCell, index) => {
    return index === 0 ? (
      <>
        <TableTogglerButton
          language={language}
          isOpen={toggleTableBody}
          toggleHandler={toggleTableHandler}
        />
        {headerCell}
      </>
    ) : (
      headerCell
    )
  })

  const rows = bodyRow.map((rowCells) => {
    return rowCells.map((rowCell, index) => {
      return index === 0 ? (
        <>
          <div className={styles.toggleButton} />
          {rowCell}
        </>
      ) : (
        rowCell
      )
    })
  })

  return (
    <section
      className={styles.memberDashboardWrapper}
      data-test="membership-dashboard"
    >
      <Container>
        <div className={styles.memberDashboard}>
          <div className={styles.memberDashboardScroll}>
            <MembershipDetail language={language} userInfo={userInfo} />
            <Table
              language={language}
              rows={rows}
              columns={columns}
              showBody={toggleTableBody}
              className={styles.listTable}
            />
          </div>
        </div>
      </Container>
    </section>
  )
}

// Props Validation
MembershipDashboard.propTypes = {
  language: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired,
  headerRow: PropTypes.array,
  bodyRow: PropTypes.array,
}

export default MembershipDashboard
