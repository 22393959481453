import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

const BannerLoader = ({ title, height = '100%', width = '100%' }) => {
  return (
    <ContentLoader
      width="100%"
      height={height}
      title={title}
      uniqueKey="banner-loader"
      data-test="banner-loader"
    >
      <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
    </ContentLoader>
  )
}
// Proptype validation
BannerLoader.propTypes = {
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
}
export default BannerLoader
