import PropTypes from 'prop-types'
import Heading from 'shared/Heading'
import styles from './DashBoardItemTitle.module.scss'
import classNames from 'classnames'

function DashBoardItemTitle({ className, ...props }) {
  const classes = classNames(className, styles.dashBoardItem)
  return <Heading as="h2" className={classes} {...props} />
}

DashBoardItemTitle.propTypes = {
  className: PropTypes.string,
}

export default DashBoardItemTitle
